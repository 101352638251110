<style scoped></style>

<template>
  <router-view class="evaluation-router-page" />
</template>

<script>
import Vue from 'vue'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
Vue.use(ViewUI)

export default {

}
</script>
